import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import programs from '../../Components/Programs/ProgramsData';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import { FaCheck } from 'react-icons/fa6';
import ApplyNowModal from '../../Components/Apply Now/ApplyNowModal';

export const Course = () => {

    const { category, subCategory } = useParams();
    const program = programs.find(p => p.category === category);
    const sub = program.subCategories.find(s => s.name === subCategory);

    const [expanded, setExpanded] = useState(null);
    const [expandedFAQ, setExpandedFAQ] = useState(null);

    const toggleAccordion = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    const toggleFAQAccordion = (index) => {
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div>
            {sub.courses.map((course, index) => (
                <div key={index}>
                    <div>
                        <img src={course.cover} alt={course.title} preload="auto" className='w-full' />
                    </div>
                    <div className='bg-black py-20 space-y-3'>
                        <FadeInSectionDownToUp>
                            <h1 className='text-white text-9xl xl:text-7xl sm:text-5xl max-sm:text-5xl text-center font-semibold py-5'>{course.title}</h1>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-center text-xl font-bold'>
                                {course.introBold}
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-white text-center text-xl px-52 xl:px-52 max-sm:px-10'>
                                {course.intro}
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='py-20'>
                        <div>
                            <FadeInSectionDownToUp>
                                <p className='text-6xl xl:text-6xl max-sm:text-5xl text-center'>
                                    Course <span className='font-semibold'>Modules</span>
                                </p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className="rounded-xl mx-20 my-20 max-sm:mx-5 grid grid-cols-4 xl:grid-cols-4 sm:grid-cols-3 max-sm:grid-cols-2 gap-8">
                            {sub.courses.map((course, courseIndex) => (
                                course.modules.map((module, moduleIndex) => (
                                    <div key={`${courseIndex}-${moduleIndex}`}>
                                        <div
                                            className={`flex justify-center items-center cursor-pointer rounded-[30px] shadow-xl h-32 w-full
                                            ${expanded === moduleIndex ? 'bg-black text-white h-32' : 'bg-[#e2e8f0] text-[#545454]'}`}
                                            onClick={() => toggleAccordion(moduleIndex)}
                                        >
                                            <h5 className="text-lg font-semibold px-5">
                                                <FadeInSectionDownToUp>
                                                    {module.title}
                                                </FadeInSectionDownToUp>
                                            </h5>
                                        </div>
                                        <div
                                            className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expanded === moduleIndex ? 'max-h-max rounded-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                                                }`}
                                        >
                                            <FadeInSectionDownToUp>
                                                <div className="p-4 bg-white space-y-5 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                                    <ul>
                                                        {module.modulePoints.map((point, idx) => (
                                                            <li className='list-disc py-3' key={idx}>{point}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </FadeInSectionDownToUp>
                                        </div>
                                    </div>
                                ))
                            ))}
                        </div>
                    </div>
                    <div className='bg-black px-40 xl:px-32 sm:px-20 max-sm:px-10 py-32'>
                        <FadeInSectionDownToUp>
                            <p className='text-9xl xl:text-9xl max-sm:text-5xl sm:text-7xl text-center text-white font-bold'>
                                What <span className='text-[#7cc6ae]'>You'll Gain</span>
                            </p>
                        </FadeInSectionDownToUp>
                        <div className='flex justify-center items-center pt-20 px-24 xl:px-24 sm:px-0 max-sm:px-0'>
                            <div className='grid grid-cols-3 max-sm:grid-cols-1 gap-32 xl:gap-32 sm:gap-12 max-sm:gap-5'>
                                {course.gain.map((point, index) => (
                                    <div key={index}>
                                        <FadeInSectionDownToUp>
                                            <div className='flex'>
                                                <p className='text-[#7cc6ae] text-xl p-2'><FaCheck /></p>
                                                <p className='text-xl text-white'>
                                                    {point}
                                                </p>
                                            </div>
                                        </FadeInSectionDownToUp>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <FadeInSectionDownToUp>
                        <div className='flex justify-center items-center py-20'>
                            <button onClick={openModal} className='bg-black text-white py-2 rounded-3xl mx-56 max-sm:mx-0 w-40'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp>
                    <div className='py-10'>
                        <FadeInSectionDownToUp>
                            <p className='text-center text-8xl font-bold text-[#868686]'>FAQs.</p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="rounded-xl mx-72 xl:mx-72 sm:mx-40 my-20 max-sm:mx-5 space-y-5">
                        <div className="rounded-xl my-20 space-y-5">
                            {course.faq.map((item, index) => (
                                <div key={index}>
                                    <div
                                        className={`p-5 cursor-pointer rounded-xl shadow-xl h-full w-full
                                        ${expandedFAQ === index ? 'bg-black text-white h-auto rounded-t-xl rounded-b-none ease-linear transition-all duration-100' : 'bg-black text-white'}`}
                                        onClick={() => toggleFAQAccordion(index)}
                                    >
                                        <h5 className="text-lg font-semibold">
                                            <FadeInSectionDownToUp>{item.question}</FadeInSectionDownToUp>
                                        </h5>
                                    </div>
                                    <div
                                        className={`transition-max-height duration-700 ease-in-out overflow-hidden ${expandedFAQ === index ? 'max-h-max rounded-b-xl shadow-lg opacity-100' : 'max-h-0 opacity-0'
                                            }`}
                                    >
                                        <div className="p-4 text-lg max-sm:text-sm px-10 max-sm:px-4">
                                            <p>{item.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            <ApplyNowModal isOpen={isModalOpen} onClose={closeModal} />
      {/* <div className="fixed top-1/3 right-0 z-50 rotate-90">
        <a
          href="/enquiry-form"
          rel="noopener noreferrer"
        >
          <p className="absolute bg-blue-800 hover:bg-blue-900 text-white text-lg text-center py-1 w-[230px] rounded-b-xl animate-bounce">
            Admissions Open 2025
          </p>
        </a>
      </div> */}
        </div>
    )
}
