import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function ComprehensiveBusinessSolutions() {

    const solutions = [
        {
            title: "Creator",
            description:
                "Creator combines the simplicity of low-code development with the power of AI, allowing you to quickly design, create, and deploy tailored business applications. Streamline your processes and drive innovation by building solutions that meet your unique business needs.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/Creator.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-20",
        },
        {
            title: "Flow",
            description:
                "Flow empowers you to create seamless integrations and automate workflows, no matter your business or role. Simplify complex processes and connect your apps effortlessly to boost efficiency and productivity.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/Flow.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Ignite",
            description:
                "Ignite is a full-stack cloud development platform that simplifies complex processes. Build, test, host, deploy, and optimize solutions effortlessly with an intuitive interface. Its easy learning curve and low total cost of ownership (TCO) empower you to create innovative solutions with ease.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/Ignite.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
        {
            title: "Office Editors",
            description:
                "Office Editors offer a powerful suite of productivity tools to create, edit, and collaborate on documents, spreadsheets, and presentations. With seamless integration and real-time collaboration features, our office editors streamline your workflow, making it easier to work efficiently and stay connected with your team, no matter where you are.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/Office%20Editors.png",
            gradient: "from-[#db7048] to-[#b94a25]",
            imgWidth: "w-16",
        },
        {
            title: "MHmail",
            description:
                "MHmail offers easy setup, reliable inbox placement, and fast delivery. A secure email service optimized for transactional emails, MHmail ensures your important messages reach their destination quickly and reliably, every time.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/MH%20Mail.png",
            gradient: "from-[#26a2af] to-[#173362]",
            imgWidth: "w-16",
        },
        {
            title: "MH Q Engine",
            description:
                "MH Q Engine allows you to manage all test cases across platforms in one place, combining no-code, low-code, and manual coding features. Create, develop, manage, and execute automated functional tests while collaborating seamlessly with your team. MH Q Engine provides everything you need to run a comprehensive and efficient testing lifecycle.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/MH%20Q%20Engine.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "Tables",
            description:
                "MH Tables effortlessly connects people, processes, and information, providing a unified platform to streamline collaboration and enhance productivity across your organization.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/Tables.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "MH RPA",
            description:
                "MH RPA makes automation easy and accessible. Easily automate challenging, repetitive, rule-based operations without the need for coding. Record your steps and let the MH RPA bots handle the rest, saving you time and increasing productivity.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/MH%20RPA%20makes.png",
            gradient: "from-[#f6592d] to-[#fa3d8f]",
            imgWidth: "w-16",
        },
        {
            title: "MH insights",
            description:
                "MH Insights empowers developers, marketers, product owners, and analysts to track metrics, analyze engagement, and optimize features. Simplify decision-making with a unified platform that brings all your data together.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/MH%20Insights.png",
            gradient: "from-[#fb2c08be] to-[#7499f8]",
            imgWidth: "w-16",
        },
        {
            title: "MH Vision",
            description:
                "MH Vision empowers businesses to deliver powerful analytics under their brand. Seamlessly integrate our advanced analytics platform into your applications or create a fully branded portal. Accessible via web and mobile, MH Vision provides your users with actionable insights to drive smarter decisions and fuel growth.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/MH%20Vision.png",
            gradient: "from-[#74bafb] to-[#318e94]",
            imgWidth: "w-16",
        },
        {
            title: "MH Data Prep",
            description:
                "Simplify data management with MH DataFlow. Automate data pipelines and harness the power of AI to clean, prepare, and organize your data, ensuring it's always ready for insightful analysis and informed decisions.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Comprehensive%20Business%20Solutions/Simplify%20data.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
    ];

    return (
        <div>
            {/* <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/Comprehensive%20Business%20Solutions.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-64 xl:px-64 sm:px-5 max-sm:px-5 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-2xl'>Comprehensive Business Solutions</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-xl px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                        Empower developers with our tools designed to simplify and accelerate application development. From low-code solutions to advanced analytics, our platform enables seamless integrations, automation, and robust security, allowing developers to focus on innovation and efficiency.
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
            <div className="relative h-screen flex items-center justify-center">
                <video autoPlay loop playsInline className="absolute inset-0 w-full h-full object-cover">
                    <source src="https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/Comprehensive%20Business%20Solutions.mp4" type="video/mp4" />
                </video>
                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl bg-black bg-opacity-60"></div>
                <div className="relative z-10 px-6 md:px-16 lg:px-10 py-20 md:py-20 max-sm:m-5 bg-opacity-50 backdrop-filter backdrop-blur-sm w-1/2 xl:w-1/2 sm:w-3/4 max-sm:w-full rounded-3xl">
                    <FadeInSectionDownToUp>
                        <h1 className="font-extrabold text-4xl md:text-5xl lg:text-6xl text-white text-center leading-tight">
                            Comprehensive Business Solutions
                        </h1>
                        <p className="mt-6 text-lg md:text-xl lg:text-2xl text-gray-200">
                            Empower developers with our tools designed to simplify and accelerate application development. From low-code solutions to advanced analytics, our platform enables seamless integrations, automation, and robust security, allowing developers to focus on innovation and efficiency.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className="flex justify-center items-center px-80 xl:px-72 sm:px-10 max-sm:px-5 py-20">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5">
                    {solutions.map(({ title, description, imgSrc, gradient, imgWidth }, index) => (
                        <div
                            key={index}
                            className={`relative bg-gradient-to-br hover:bg-gradient-to-tl ${gradient} rounded-3xl text-white p-8 h-[500px]`}
                        >
                            <FadeInSectionDownToUp>
                                <h3 className="text-5xl font-extrabold">{title}</h3>
                                <p className="text-lg pt-5">{description}</p>
                            </FadeInSectionDownToUp>
                            <div className="absolute bottom-10 right-10">
                                <FadeInSectionDownToUp>
                                    <img src={imgSrc} alt={title} className={imgWidth} />
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
