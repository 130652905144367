import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function LMS() {

    const solutions = [
        {
            title: "Personalized Learning",
            description: "Builder combines the power of low-code development and AI, allowing you to easily design, develop, and deploy custom business applications tailored to your needs, without the complexity of traditional coding.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/Personalized%20Learning.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "Progress Tracking",
            description: "Stay on top of learning advancements with real-time progress tracking. Students can track their achievements, areas needing attention, and course completions, while educators can monitor performance to provide timely support. This ensures that both learners and instructors are always aligned with educational goals.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/progress%20tracking.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "AI Tools",
            description: "Leverage AI-driven insights to enhance student engagement and personalize the learning experience. The LMS analyzes student behavior, predicts potential challenges, and delivers adaptive content tailored to each learner's needs. AI tools also automate assessments and feedback, speeding up the learning process and boosting performance.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/AI%20Tools.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
        {
            title: "Interactive Content",
            description: "Engage students with interactive content such as quizzes, multimedia presentations, simulations, and games. This dynamic approach promotes active participation, improves knowledge retention, and ensures a fun and stimulating learning journey. Learners are encouraged to explore and interact with the content to deepen their understanding.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/Interactive%20Content.png",
            gradient: "from-[#db7048] to-[#b94a25]",
            imgWidth: "w-16",
        },
        {
            title: "Continuous Feedback",
            description: "Continuous assessment is made easy with the LMS’s built-in tools for tests, quizzes, assignments, and instant feedback. Educators can track student performance while providing constructive feedback that motivates improvement. This continuous feedback loop fosters both academic growth and confidence in learners.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/Continuous%20Feedback.png",
            gradient: "from-[#26a2af] to-[#173362]",
            imgWidth: "w-16",
        },
        {
            title: "Collaborative Learning",
            description: "Encourage peer-to-peer learning and teamwork through features that allow for group projects, discussion forums, and collaborative tasks. These features help build a sense of community among learners, fostering collaborative learning environments in both physical and virtual spaces.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/Collaborative%20Learning.png",
            gradient: "from-[#fb2c08be] to-[#7499f8]",
            imgWidth: "w-16",
        },
        {
            title: "Mobile Learning",
            description: "With mobile compatibility, an LMS ensures that students can learn from anywhere, anytime. Whether using a desktop, tablet, or smartphone, learners enjoy the flexibility to study at their own pace and convenience, making learning more accessible and engaging, no matter the time or place.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/Mobile%20Learning.png",
            gradient: "from-[#74bafb] to-[#318e94]",
            imgWidth: "w-16",
        },
        {
            title: "Scalable Solutions",
            description: "The LMS is highly scalable, making it ideal for a variety of learning environments, including K-12, higher education, corporate training, and virtual learning settings. It’s suited to both small groups and large-scale organizations, ensuring that the platform can grow with your educational needs.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS/Scalable%20Solutions.png",
            gradient: "from-[#f6592d] to-[#fa3d8f]",
            imgWidth: "w-16",
        },
    ];

    return (
        <div>
            {/* <div >
                    <video autoPlay loop playsInline className='w-full'>
                        <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/LMS_Lap_1.mp4"} type='video/mp4' />
                    </video>
                </div>
                <div className='px-64 xl:px-64 sm:px-5 max-sm:px-5 py-20 max-sm:py-10 text-center'>
                    <FadeInSectionDownToUp>
                        <p className='font-semibold py-2 text-5xl max-sm:text-2xl'>Learning Management System</p>
                        <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-xl px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                            MH Cognition’s Learning Management System (LMS) is an advanced platform that improves learning for students, teachers, and organizations. It combines AI and interactive content with effective teaching methods to create a flexible and engaging learning experience.
                        </p>
                    </FadeInSectionDownToUp>
                </div> */}
            <div className="relative h-screen flex items-center justify-end">
                <video autoPlay loop playsInline className="absolute inset-0 w-full h-full object-cover">
                    <source src="https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/LMS_Lap_1.mp4" type="video/mp4" />
                </video>
                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl bg-black bg-opacity-50"></div>
                <div className="relative z-10 px-6 md:px-16 lg:px-10 py-20 md:py-20 max-sm:m-5 text-white xl:bg-transparent sm:bg-black max-sm:bg-black sm:bg-opacity-50 max-sm:bg-opacity-50 backdrop-filter backdrop-blur-sm w-1/2 xl:w-1/3 sm:w-3/4 max-sm:w-full rounded-3xl mr-10 lg:mr-20">
                    <FadeInSectionDownToUp>
                        <h1 className="font-extrabold text-4xl leading-tight text-center">
                            Learning Management System
                        </h1>
                        <p className="mt-6 text-lg md:text-xl lg:text-2xl">
                            MH Cognition’s Learning Management System (LMS) is an advanced platform that improves learning for students, teachers, and organizations. It combines AI and interactive content with effective teaching methods to create a flexible and engaging learning experience.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>

            <div className="flex justify-center items-center px-80 xl:px-72 sm:px-10 max-sm:px-5 py-20">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5">
                    {solutions.map(({ title, description, imgSrc, gradient, imgWidth }, index) => (
                        <div
                            key={index}
                            className={`relative bg-gradient-to-br hover:bg-gradient-to-tl ${gradient} rounded-3xl text-white p-8 h-[500px]`}
                        >
                            <FadeInSectionDownToUp>
                                <h3 className="text-4xl font-extrabold">{title}</h3>
                                <p className="text-lg pt-5">{description}</p>
                            </FadeInSectionDownToUp>
                            <div className="absolute bottom-10 right-10">
                                <FadeInSectionDownToUp>
                                    <img src={imgSrc} alt={title} className={imgWidth} />
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
