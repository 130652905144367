import React from "react";
import { Link } from "react-router-dom";
import programs from "../Programs/ProgramsData";

const Footer = () => {
  return (
    <footer className="bg-gray-200 text-start">
      <div className="mb-4 grid grid-cols-4 xl:grid-cols-4 max-sm:grid-cols-1 gap-5 p-6">
        {programs.map((program) => (
          <div key={program.category} className="my-2">
            <Link to={`/education/certification-programs/${program.category}`} className="text-blue-600 hover:underline">
              {program.category}
            </Link>
            {program.subCategories && program.subCategories.map((subCategory) => (
              <h1>
                <Link
                  key={subCategory.name} // Use the name as the key
                  to={`/education/certification-programs/${program.category}/${subCategory.name}`}
                  className="text-gray-600 hover:underline"
                >
                  {subCategory.name} {/* Render the name property */}
                </Link>
              </h1>
            ))}
          </div>
        ))}
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6964.840897974084!2d80.162918!3d12.952189497405955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525ff735762c0b%3A0x67fc261c21b03b23!2sMH%20Cognition!5e0!3m2!1sen!2sin!4v1735038661360!5m2!1sen!2sin" width="100%" height="300" title="MH Cognition" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <div className="text-black font-semibold text-sm text-center py-2 flex max-sm:block justify-around items-center max-sm:space-y-1">
        <p>Contact: <a href="tel:7305952831">7305952831</a> / <a href="tel:9884807480">9884807480</a>.</p>
        <p>&copy; {new Date().getFullYear()} MH Cognition. All rights reserved.</p>
        <a href="mailto:info@mhcognition.com">info@mhcognition.com</a>
      </div>
    </footer>
  );
};

export default Footer;
