import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function CRM() {

    const solutions = [
        {
            title: "Customer Relationship Management",
            description: "Centralizes customer information for easy access and updates, tracking interactions to build stronger relationships and improve satisfaction.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Customer%20Relationship%20Management.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "Lead Management",
            description: "Captures and organizes leads from multiple sources in one platform, automating lead assignment to ensure timely follow-ups.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Lead%20Management.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Sales Pipeline Tracking",
            description: "Visualizes the sales pipeline to monitor deal progress and helps forecast revenue with real-time sales data.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Sales%20Pipeline%20Tracking.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
        {
            title: "Marketing Integration",
            description: "Automates email campaigns and tracks their performance, segmenting customers for personalized marketing efforts.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Marketing%20Integration.png",
            gradient: "from-[#db7048] to-[#b94a25]",
            imgWidth: "w-16",
        },
        {
            title: "Customer Support",
            description: "Manages service tickets and tracks resolutions efficiently, providing tools for live chat, email support, and call logging.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Customer%20Support.png",
            gradient: "from-[#26a2af] to-[#173362]",
            imgWidth: "w-16",
        },
        {
            title: "Analytics and Reporting",
            description: "Generates actionable insights through detailed customer reports, tracking KPIs to evaluate the success of sales and marketing efforts.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Analytics%20and%20Reporting.png",
            gradient: "from-[#fb2c08be] to-[#7499f8]",
            imgWidth: "w-16",
        },
        {
            title: "Collaboration",
            description: "Promotes team collaboration with shared calendars and task tracking, facilitating smooth handovers between sales, support, and marketing teams.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Collaboration.png",
            gradient: "from-[#74bafb] to-[#318e94]",
            imgWidth: "w-16",
        },
        {
            title: "Automation",
            description: "Automates routine tasks like data entry and follow-up reminders, saving time for teams to focus on building customer relationships.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Automation.png",
            gradient: "from-[#f6592d] to-[#fa3d8f]",
            imgWidth: "w-16",
        },
        {
            title: "Integration",
            description: "Syncs seamlessly with other tools like ERP, LMS, and email platforms, allowing third-party app connections for extended functionalities.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Integration.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Customization and Scalability",
            description: "Offers flexible modules to adapt to your business needs, scaling with your organization as it grows and evolves.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/CRM/Customization%20and%20Scalability.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
    ];

    return (
        <div>
            {/* <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/CRM%20&%20ERP.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-64 xl:px-64 sm:px-5 max-sm:px-5 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-2xl'>Comprehensive Business Solutions</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-xl px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                        Optimize customer relationships with MH Cognition's innovative CRM platform. Empower your team with tools for lead management, sales tracking, and actionable insights, while ensuring seamless communication and collaboration. Our system supports personalized customer interactions, driving loyalty, efficiency, and business growth.Experience the power of intuitive design and unmatched scalability tailored to your business needs.
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
            <div className="relative h-screen flex items-center justify-center">
                <video autoPlay loop playsInline className="absolute inset-0 w-full h-full object-cover">
                    <source src="https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/CRM%20&%20ERP.mp4" type="video/mp4" />
                </video>
                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl bg-black bg-opacity-60"></div>
                <div className="relative z-10 px-6 md:px-16 lg:px-10 py-20 md:py-20 max-sm:m-5 bg-opacity-50 backdrop-filter backdrop-blur-sm w-1/2 xl:w-1/2 sm:w-3/4 max-sm:w-full rounded-3xl">
                    <FadeInSectionDownToUp>
                        <h1 className="font-extrabold text-4xl md:text-5xl lg:text-6xl text-white text-center leading-tight">
                            Comprehensive Business Solutions
                        </h1>
                        <p className="mt-6 text-lg md:text-xl lg:text-2xl text-gray-200">
                            Optimize customer relationships with MH Cognition's innovative CRM platform. Empower your team with tools for lead management, sales tracking, and actionable insights, while ensuring seamless communication and collaboration. Our system supports personalized customer interactions, driving loyalty, efficiency, and business growth.Experience the power of intuitive design and unmatched scalability tailored to your business needs.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className="flex justify-center items-center px-80 xl:px-72 sm:px-10 max-sm:px-5 py-20">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5">
                    {solutions.map(({ title, description, imgSrc, gradient, imgWidth }, index) => (
                        <div
                            key={index}
                            className={`relative bg-gradient-to-br hover:bg-gradient-to-tl ${gradient} rounded-3xl text-white p-8 h-[500px]`}
                        >
                            <FadeInSectionDownToUp>
                                <h3 className="text-4xl font-extrabold">{title}</h3>
                                <p className="text-lg pt-5">{description}</p>
                            </FadeInSectionDownToUp>
                            <div className="absolute bottom-10 right-10">
                                <FadeInSectionDownToUp>
                                    <img src={imgSrc} alt={title} className={imgWidth} />
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
