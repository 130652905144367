import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'
import Marquee from 'react-fast-marquee'

export default function VelsUniversity() {

    const benefits = [

        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Comprehensive%20Knowledge%20Base.png",
            title: "Comprehensive Knowledge Base",
            desc: "Gain a solid understanding of core principles across various computer science domains.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Practical%20Training.png",
            title: "Practical Training",
            desc: "Acquire hands-on experience with leading tools and platforms relevant to multiple specializations.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Strong%20Industry%20Partnerships.png",
            title: "Strong Industry Partnerships",
            desc: "Access internships and job placements through collaborations with top tech companies.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Experienced%20Faculty.png",
            title: "Experienced Faculty",
            desc: "Learn from instructors with extensive industry backgrounds and expertise in diverse fields.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Advanced%20Tools%20and%20Resources.png",
            title: "Advanced Tools and Resources",
            desc: "Utilize state-of-the-art software and technologies for practical learning and project work.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Collaborative%20Projects.png",
            title: "Collaborative Projects",
            desc: "Engage in team-based projects that simulate real-world scenarios across disciplines.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Focus%20on%20Emerging%20Trends.png",
            title: "Focus on Emerging Trends",
            desc: "Explore innovative topics relevant to the evolving landscape of technology.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Capstone%20Experience.png",
            title: "Capstone Experience",
            desc: "Work on significant projects addressing real business challenges across various sectors.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Professional%20Networking.png",
            title: "Professional Networking",
            desc: "Connect with industry professionals through workshops, seminars, and university events.",
        },
        {
            icon: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Icons/Black/Diverse%20Career%20Paths.png",
            title: "Diverse Career Paths",
            desc: "Prepare for a variety of roles in technology, including cloud architect, DevOps engineer, data analyst, and full stack developer.",
        },

    ]

    const recruiters = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Amazon.png",
            alt: "Amazon",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Google.png",
            alt: "Google",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Accenture.png",
            alt: "Accenture",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Microsoft.png",
            alt: "Microsoft",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Meta.png",
            alt: "Meta",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Apple.png",
            alt: "Apple",
            size: "w-40 max-sm:w-20",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Oracle.png",
            alt: "Oracle",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/IBM.png",
            alt: "IBM",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Bloomberg.png",
            alt: "Bloomberg",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/MH-Cognition.png",
            alt: "MH Cognition",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Open-AI.png",
            alt: "Open AI",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Deloitee.png",
            alt: "Deloitte",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/GSK.png",
            alt: "GSK",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/SAP.png",
            alt: "SAP",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Intel.png",
            alt: "Intel",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Tesla.png",
            alt: "Tesla",
            size: "w-40 max-sm:w-20",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Nvidia.png",
            alt: "Nvidia",
            size: "w-60 max-sm:w-36",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Recruiters/Walmart.png",
            alt: "Walmart",
            size: "w-60 max-sm:w-36",
        },

    ]


    return (
        <div>
            <div className='relative xl:pt-0 sm:pt-12'>
                <div>
                    <img src='https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Vels%20University.jpg' className='w-full' alt='Vels University' />
                </div>
                <div className='flex justify-center items-center absolute bottom-0 xl:bottom-0 sm:-bottom-5 max-sm:-bottom-5 left-1/3 right-1/3'>
                    <FadeInSectionDownToUp>
                        <img className='w-96' src='https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Vels%20University/Advantages%20of%20Advanced%20Computing%20at%20Vels%20University.png' alt='Vels University' />
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='py-20 px-40 xl:px-40 sm:px-20 max-sm:px-5'>
                <div className='flex justify-center items-center'>
                    <div className='grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5'>
                        {benefits.map((tex, index) => (
                            <FadeInSectionDownToUp>
                                <div key={index} className='flex justify-center items-center gap-3 shadow-inner border-2 p-5 rounded-xl'>
                                    <div className='w-1/4 max-sm:w-full flex justify-center items-center'>
                                        <img className='w-20' src={tex.icon} alt={tex.title} />
                                    </div>
                                    <div className='space-y-2'>
                                        <p className='text-xl font-semibold'>
                                            {tex.title}
                                        </p>
                                        <p>
                                            {tex.desc}
                                        </p>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                        ))}
                    </div>
                </div>
            </div>
            <div className='py-20 bg-[#e9e9e9]'>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-5xl text-[#2400ff] text-center font-extrabold'>Job Demand & <br />Industry Growth</p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='flex justify-center items-center py-10'>
                    <FadeInSectionDownToUp>
                        <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-20'>
                            <div className='text-start'>
                                <p className='text-6xl text-[#2400ff] font-extrabold'>1M</p>
                                <p className='text-xl text-[#2400ff]'>New jobs by<br />FY2028</p>
                                <p className='text-gray-500'>AI & Robotics</p>
                            </div>
                            <div className='text-start'>
                                <p className='text-6xl text-[#2400ff] font-extrabold'>39%</p>
                                <p className='text-xl text-[#2400ff]'>Increase by<br />FY2025</p>
                                <p className='text-gray-500'>AI & Robotics</p>
                            </div>
                            <div className='text-start'>
                                <p className='text-6xl text-[#2400ff] font-extrabold'>30%</p>
                                <p className='text-xl text-[#2400ff]'>Increase by<br />FY2025</p>
                                <p className='text-gray-500'>Cloud Computing</p>
                            </div>
                            <div className='text-start'>
                                <p className='text-6xl text-[#2400ff] font-extrabold'>20%</p>
                                <p className='text-xl text-[#2400ff]'>Increase by<br />FY2029</p>
                                <p className='text-gray-500'>Full-Stack</p>
                            </div>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <div className='py-20 max-sm:py-10 max-sm:px-5 mx-48 xl:mx-48 sm:mx-20 max-sm:mx-5 bg-white rounded-full xl:rounded-full sm:rounded-3xl max-sm:rounded-xl flex max-sm:block justify-center items-center gap-16 max-sm:space-y-10'>
                            <div className='grid grid-cols-2 gap-16 max-sm:gap-10'>
                                <div className='text-start'>
                                    <p className='text-5xl max-sm:text-4xl text-[#2400ff] font-extrabold'>$5.75T</p>
                                    <p className='text-xl max-sm:text-base text-[#2400ff]'>Global IT Investment<br />by FY2025</p>
                                    <p className='text-gray-500'>Global IT Investment</p>
                                </div>
                                <div className='text-start'>
                                    <p className='text-5xl max-sm:text-4xl text-[#2400ff] font-extrabold'>150k</p>
                                    <p className='text-xl max-sm:text-base text-[#2400ff]'>Freshers will be hired<br />by FY2025</p>
                                    <p className='text-gray-500'>IT Sector Growth</p>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-16 max-sm:gap-10'>
                                <div className='text-start'>
                                    <p className='text-5xl max-sm:text-4xl text-[#2400ff] font-extrabold'>6M</p>
                                    <p className='text-xl max-sm:text-base text-[#2400ff]'>Tech jobs in<br />FY2024</p>
                                    <p className='text-gray-500'>Tech job growth</p>
                                </div>
                                <div className='text-start'>
                                    <p className='text-5xl max-sm:text-4xl text-[#2400ff] font-extrabold'>7.1M</p>
                                    <p className='text-xl max-sm:text-base text-[#2400ff]'>Tech jobs by<br />FY2034</p>
                                    <p className='text-gray-500'>Tech job growth</p>
                                </div>
                            </div>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
                <div className='flex max-sm:block justify-center items-center gap-5 pt-10'>
                    <div className='max-sm:text-center'>
                        <FadeInSectionDownToUp>
                            <p className='text-8xl max-sm:text-6xl text-[#2400ff] font-extrabold'>GCC<span className='text-6xl text-[#2400ff] font-extrabold'>s</span></p>
                            {/* <p className='text-sm text-[#2400ff] text-end max-sm:text-center'>Global Capability Centres</p> */}
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='max-sm:text-center'>
                        <FadeInSectionDownToUp>
                            <div>
                                <p className='text-3xl max-sm:text-4xl text-[#2400ff]'><b>600k</b> from FY 2018 - 2024</p>
                            </div>
                            <div>
                                <p className='text-3xl max-sm:text-4xl text-[#2400ff]'><b>2.8M</b> by FY 2030</p>
                            </div>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div>
                    <FadeInSectionDownToUp>
                        <p className='text-5xl max-sm:text-4xl text-[#2400ff] text-center'>Jobs Created.</p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-3xl font-semibold pt-20'>
                        Top Recruiters
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='overflow-hidden'>
                <Marquee speed={100} loop={1000}>
                    {recruiters.map((img, index) => (
                        <img key={index} className={`${img.size} px-10 py-5 max-sm:py-5 max-sm:px-5`} src={img.src} alt={img.alt} />
                    ))}
                </Marquee>
            </div>
            <div className='flex justify-center items-center px-32 xl:px-32 sm:px-10 max-sm:px-5 py-10'>
                <div className='grid grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 max-sm:grid-cols-1 gap-10'>
                    <div>
                        <div className='p-5 bg-[#8d24e0] text-white rounded-xl w-full h-full'>
                            <FadeInSectionDownToUp>
                                <p className='text-xl font-semibold py-2'>Courses Offered.</p>
                            </FadeInSectionDownToUp>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                BCA Cloud Computing.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                B.Sc Computer Science (Full Stack Development).
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                B.Tech Robotics and Artificial Intelligence.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-full'>
                        <div className='p-5 bg-[#8d24e0] text-white rounded-xl w-full h-full'>
                            <FadeInSectionDownToUp>
                                <p className='text-xl font-semibold py-2'>Eligibility.</p>
                            </FadeInSectionDownToUp>
                            <div className="text-lg text-justify">
                                <FadeInSectionDownToUp>
                                    <p>
                                        10th & 12th with Maths & Physics or its equivalent <br className='max-sm:hidden xl:block sm:hidden' />
                                        such as (3 years Engineering Diploma) from a <br className='max-sm:hidden xl:block sm:hidden' />
                                        Recognised Board.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='flex justify-center animate-bounce'>
                <FadeInSectionDownToUp>
                    <a
                        className='bg-gradient-to-tl from-[#8d24e0] to-[#2971ea] text-white text-xl font-semibold p-3 rounded-lg'
                        href='https://erp.vistas.ac.in/velsonline/applicationist/loginManager/applicantRegistration.jsp'
                        target='blank'
                    >
                        Apply Now
                    </a>
                </FadeInSectionDownToUp>
            </div> */}
        </div>
    )
}
