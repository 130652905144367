import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function HumanResourcesSolutions() {

    const solutions = [
        {
            title: "People Management",
            description: "Streamline workforce operations by centralizing all employee data and profiles, fostering a collaborative environment with tools to engage and support your people.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/People%20Management.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "Recruitment and Onboarding",
            description: "Simplify hiring with advanced tools to post jobs, track applicants, and recruit top talent, providing seamless onboarding experiences to help new hires integrate quickly.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/Recruitment%20and%20Onboarding.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Expense Management",
            description: "Track and manage employee expenses with automated approvals and reporting, ensuring accuracy and compliance with real-time expense monitoring.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/Expense%20Management.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
        {
            title: "Workerly Operations",
            description: "Optimize workflows by managing tasks, roles, and workforce allocations, empowering workers with tools for self-service and real-time communication.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/Workerly%20Operations.png",
            gradient: "from-[#db7048] to-[#b94a25]",
            imgWidth: "w-16",
        },
        {
            title: "Payroll Processing",
            description: "Automate payroll calculations to ensure timely and accurate payments, integrating with attendance and expense systems for streamlined payroll management.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/Payroll%20Processing.png",
            gradient: "from-[#26a2af] to-[#173362]",
            imgWidth: "w-16",
        },
        {
            title: "Shift Management",
            description: "Schedule shifts effectively to balance workloads and meet business needs, allowing workers to view, swap, or sign up for shifts with ease.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/Shift%20Management.png",
            gradient: "from-[#fb2c08be] to-[#7499f8]",
            imgWidth: "w-16",
        },
        {
            title: "Sign and Approvals",
            description: "Enable e-signatures for contracts, approvals, and policy acknowledgments, reducing paperwork and speeding up processes with secure digital sign solutions.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/Sign%20and%20Approvals.png",
            gradient: "from-[#74bafb] to-[#318e94]",
            imgWidth: "w-16",
        },
        {
            title: "People Plus Engagement",
            description: "Enhance employee engagement with feedback tools, surveys, and recognition programs, offering personalized growth opportunities to boost satisfaction and retention.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/People%20Plus%20Engagement.png",
            gradient: "from-[#f6592d] to-[#fa3d8f]",
            imgWidth: "w-16",
        },
        {
            title: "Compliance and Reporting",
            description: "Ensure adherence to labor laws and regulations with automated compliance checks, generating detailed reports on expenses, payroll, and workforce trends.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/Compliance%20and%20Reporting.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "HR Analytics",
            description: "Leverage insights to optimize recruitment, shifts, and worker performance, using data to make informed decisions and align HR strategies with business goals.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HR/HR%20Analytics.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
    ];

    return (
        <div>
            {/* <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/Human%20Resources%20Solutions.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-64 xl:px-64 sm:px-5 max-sm:px-5 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-2xl'>Human Resources Solutions</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-xl px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                        Simplify your recruitment process with MH Cognition. From posting job openings to streamlining candidate selection, our platform helps you attract, evaluate, and hire top talent efficiently. Save time, improve your hiring process, and build stronger teams.
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
            <div className="relative h-screen flex items-center justify-center">
                <video autoPlay loop playsInline className="absolute inset-0 w-full h-full object-cover">
                    <source src="https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/Human%20Resources%20Solutions.mp4" type="video/mp4" />
                </video>
                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl bg-black bg-opacity-60"></div>
                <div className="relative z-10 px-6 md:px-16 lg:px-10 py-20 md:py-20 max-sm:m-5 bg-opacity-50 backdrop-filter backdrop-blur-sm w-1/2 xl:w-1/2 sm:w-3/4 max-sm:w-full rounded-3xl">
                    <FadeInSectionDownToUp>
                        <h1 className="font-extrabold text-4xl md:text-5xl lg:text-6xl text-white text-center leading-tight">
                            Human Resources Solutions
                        </h1>
                        <p className="mt-6 text-lg md:text-xl lg:text-2xl text-gray-200">
                            Simplify your recruitment process with MH Cognition. From posting job openings to streamlining candidate selection, our platform helps you attract, evaluate, and hire top talent efficiently. Save time, improve your hiring process, and build stronger teams.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className="flex justify-center items-center px-80 xl:px-72 sm:px-10 max-sm:px-5 py-20">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5">
                    {solutions.map(({ title, description, imgSrc, gradient, imgWidth }, index) => (
                        <div
                            key={index}
                            className={`relative bg-gradient-to-br hover:bg-gradient-to-tl ${gradient} rounded-3xl text-white p-8 h-[500px]`}
                        >
                            <FadeInSectionDownToUp>
                                <h3 className="text-4xl font-extrabold">{title}</h3>
                                <p className="text-lg pt-5">{description}</p>
                            </FadeInSectionDownToUp>
                            <div className="absolute bottom-10 right-10">
                                <FadeInSectionDownToUp>
                                    <img src={imgSrc} alt={title} className={imgWidth} />
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
