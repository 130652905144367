const metaDescriptions = {

  '/': {
    description: '',
    keywords: '',
    ogTitle: 'MH Cognition'
  },
  '/enterprise': {
    description: 'Explore the leading enterprise software companies in India, offering top-notch CRM, LMS, and ERP solutions. Find the best tools to optimize your business processes and improve efficiency with trusted and innovative technology providers.',
    keywords: 'enterprise software companies | enterprise software | enterprise software development | enterprise software solutions | enterprise resource planning software | enterprise resource planning companies | enterprise software development company | enterprise application development | enterprise software development services | enterprise resource software | best enterprise resource planning software | enterprise resource planning software for small business | business enterprise software | enterprise resource planning software solution | enterprise resources planning systems | top enterprise resource planning | enterprise application software companies |  enterprise resource planning services | enterprise resource planning and management | enterprise software companies |  best enterprise software | enterprise application software companies | enterprise application development india |  enterprise resource planning software list',
    ogTitle: 'Top Enterprise Software Companies in India | CRM, LMS & ERP Solutions'
  },
  '/enterprise/learning-management-system': {
    description: 'Uncover the Top LMS Systems & Software, Best Tools for Learning & Growth. From powerful features to intuitive design, find the best platforms for education and business success. Discover how these tools can simplify learning and drive growth in your organization or institution.',
    keywords: 'lms systems | best learning management software |  education learning management system | enterprise learning management system | learning management system companies |  learning management solutions |  corporate learning management system | lms course |  learning management system software |  learner management system |  lms education |  lms website |  corporate lms | best lms software | lms companies | lms saas | lms systems |  lms software |  lms platforms |  best lms platforms |  best learning management system |  top learning management systems | lms tools | corporate lms systems | best lms in india |  best lms software in india | lms for colleges | lms for retail | lms software providers | lms software in india | learning management system for colleges | enterprise learning management',
    ogTitle: 'Top LMS Systems & Software: Best Tools for Learning & Growth'
  },
  '/enterprise/enterprise-resource-planning': {
    description: 'Leverage Enterprise Resource Planning to foster innovation, drive seamless growth, and stay ahead in a competitive marketplace.',
    keywords: 'best erp for small business |  erp system for small businesses | erp systems for small companies |  erp system |  erp solutions | erp software | enterprise resource planning |  erp application | erp ai | erp tools | enterprise resource planning systems | top erp systems  |  erp platform |  erp software for small business |  top erp software |  business erp |  erp system for small businesses |  erp programs |  erp products |  erp software list |  erp systems list |  erp saas |  erp company |  erp software solutions |  erp solutions company |  erp software company |  erp application software |  crm and erp software  |  enterprise resource planning tools | erp system solutions | erp tools and software | erp system saas',
    ogTitle: 'Enterprise Resource Planning for Seamless Growth and Innovation'
  },
  '/enterprise/customer-relationship-management': {
    description: 'Drive revenue and improve customer satisfaction by implementing the best CRM software, designed for efficiency and seamless interaction.',
    keywords: 'crm software | crm system | crm tools | best crm systems | crm applications | best crm tools | customer relationship management system | crm website | crm companies | crm software tools | crm management tools | crm relationship management | crm application software | customer relationship management companies | crm system tools | best customer relationship management | crm computer software | crm software solution | crm in services',
    ogTitle: 'Boost Your Sales with the Best CRM Software on the Market'
  },
  // '/': {
  //   description: '',
  //   keywords: '',
  //   ogTitle: 'MH Cognition'
  // },

};

export default metaDescriptions;
