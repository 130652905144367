import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function CyberSecuritySolutions() {

    const solutions = [
        {
            title: "Builder",
            description: "Builder combines the power of low-code development and AI, allowing you to easily design, develop, and deploy custom business applications tailored to your needs, without the complexity of traditional coding.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Cyber%20Security%20Solutions/Builder.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "MH Access",
            description: "MH Access simplifies employee authentication, granting them secure access to cloud apps and devices. Manage and control user interactions effortlessly, ensuring seamless and secure operations across your organization.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Cyber%20Security%20Solutions/MH%20Access.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "SecureAuth",
            description: "SecureAuth is an all-in-one authenticator app designed to streamline multi-factor authentication and enhance your business security. Safeguard your data and ensure secure access across all platforms with ease.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Cyber%20Security%20Solutions/SecureAuth.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
        {
            title: "SecureKey",
            description: "SecureKey is a secure password manager for individuals, teams, and enterprises, designed to safely store and manage passwords and sensitive information. Protect your data with ease, whether for personal use or across your organization.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Cyber%20Security%20Solutions/SecureKey.png",
            gradient: "from-[#db7048] to-[#b94a25]",
            imgWidth: "w-16",
        },
        {
            title: "AdminHub",
            description: "AdminHub is a comprehensive, free resource for all your admin-related queries. From port checks to analyzing email headers, it provides a range of functions to simplify tasks. Just enter the required value and access data in an easy-to-understand format.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Cyber%20Security%20Solutions/AdminHub.png",
            gradient: "from-[#26a2af] to-[#173362]",
            imgWidth: "w-16",
        },
        {
            title: "RemoteCare",
            description: "RemoteCare provides secure, cloud-based remote support and access, helping businesses deliver exceptional customer service worldwide. Easily establish on-demand, web-based connections to remote PCs, laptops, mobile devices, and servers, enhancing your support capabilities with a reliable and secure solution.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Cyber%20Security%20Solutions/RemoteCare.png",
            gradient: "from-[#fb2c08be] to-[#7499f8]",
            imgWidth: "w-16",
        },
    ];    

    return (
        <div>
            {/* <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Degree-Programs/B.Sc.%20Data%20science%20and%20Cybersecurity%20-%20Demo.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-64 xl:px-64 sm:px-5 max-sm:px-5 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-2xl'>Cyber Security Solutions</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-xl px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                        Protect your business with our robust cybersecurity solutions. We offer comprehensive services, including threat detection, data encryption, and network security, to safeguard your digital assets. Our proactive approach ensures resilience against cyber threats, giving you the confidence to operate securely in today’s connected world.
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
            <div className="relative h-screen flex items-center justify-center">
                <video autoPlay loop playsInline className="absolute inset-0 w-full h-full object-cover">
                    <source src="https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/Cyber%20Security%20Solutions.mp4" type="video/mp4" />
                </video>
                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl bg-black bg-opacity-60"></div>
                <div className="relative z-10 px-6 md:px-16 lg:px-10 py-20 md:py-20 max-sm:m-5 bg-opacity-50 backdrop-filter backdrop-blur-sm w-1/2 xl:w-1/2 sm:w-3/4 max-sm:w-full rounded-3xl">
                    <FadeInSectionDownToUp>
                        <h1 className="font-extrabold text-4xl md:text-5xl lg:text-6xl text-white text-center leading-tight">
                        Cyber Security Solutions
                        </h1>
                        <p className="mt-6 text-lg md:text-xl lg:text-2xl text-gray-200">
                        Protect your business with our robust cybersecurity solutions. We offer comprehensive services, including threat detection, data encryption, and network security, to safeguard your digital assets. Our proactive approach ensures resilience against cyber threats, giving you the confidence to operate securely in today’s connected world.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className="flex justify-center items-center px-80 xl:px-72 sm:px-10 max-sm:px-5 py-20">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5">
                    {solutions.map(({ title, description, imgSrc, gradient, imgWidth }, index) => (
                        <div
                            key={index}
                            className={`relative bg-gradient-to-br hover:bg-gradient-to-tl ${gradient} rounded-3xl text-white p-8 h-[500px]`}
                        >
                            <FadeInSectionDownToUp>
                                <h3 className="text-5xl font-extrabold">{title}</h3>
                                <p className="text-lg pt-5">{description}</p>
                            </FadeInSectionDownToUp>
                            <div className="absolute bottom-10 right-10">
                                <FadeInSectionDownToUp>
                                    <img src={imgSrc} alt={title} className={imgWidth} />
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
