import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function ERP() {

    const solutions = [
        {
            title: "Core Functions",
            description: "Integrates key business processes like finance, inventory, and HR into a unified system and automates repetitive tasks, reducing errors and saving time.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Core%20Functions.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "Real-Time Data",
            description: "Provides real-time updates and insights for better decision-making, ensuring accurate and consistent data across all departments.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Real-Time%20Data.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Scalability",
            description: "Adapts to the growing needs of your business as it scales, offering flexible modules that can be tailored to specific industries.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Scalability.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
        {
            title: "Collaboration",
            description: "Enhances communication between departments with seamless data sharing and facilitates coordinated workflows across different business units.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Collaboration.png",
            gradient: "from-[#db7048] to-[#b94a25]",
            imgWidth: "w-16",
        },
        {
            title: "Analytics and Reporting",
            description: "Generates detailed reports on performance, trends, and forecasts, using advanced analytics for strategic planning and efficiency optimization.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Analytics%20and%20Reporting.png",
            gradient: "from-[#26a2af] to-[#173362]",
            imgWidth: "w-16",
        },
        {
            title: "User Access and Security",
            description: "Offers role-based access to ensure data confidentiality and provides robust security measures to protect sensitive information.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/User%20Access%20and%20Security.png",
            gradient: "from-[#fb2c08be] to-[#7499f8]",
            imgWidth: "w-16",
        },
        {
            title: "Integration Capabilities",
            description: "Seamlessly integrates with existing tools like CRM, LMS, and payroll systems, supporting third-party applications for extended functionality.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Integration%20Capabilities.png",
            gradient: "from-[#74bafb] to-[#318e94]",
            imgWidth: "w-16",
        },
        {
            title: "Cost Efficiency",
            description: "Reduces operational costs by minimizing manual processes and increases ROI with streamlined and automated workflows.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Cost%20Efficiency.png",
            gradient: "from-[#f6592d] to-[#fa3d8f]",
            imgWidth: "w-16",
        },
        {
            title: "Customization",
            description: "Tailors modules to align with specific business requirements, supporting unique workflows and industry-specific processes.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Customization.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Compliance and Regulations",
            description: "Ensures adherence to local and global regulatory standards, keeping records and processes audit-ready to meet compliance needs.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/ERP/Compliance%20and%20Regulations.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
    ];

    return (
        <div>
            {/* <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/CRM%20&%20ERP.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-64 xl:px-64 sm:px-5 max-sm:px-5 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-2xl'>Enterprise Resource Planning</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-xl px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                        MH Cognition's ERP system is intended to completely transform how businesses and institutions function. This all-inclusive solution guarantees smooth administration and increased productivity by combining all academic and administrative tasks into a single, cohesive platform.  The purpose of an ERP system is to increase communication, efficiency, and transparency inside organizations. Discover the management of the future with our cutting-edge solution.
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
            <div className="relative h-screen flex items-center justify-center">
                <video autoPlay loop playsInline className="absolute inset-0 w-full h-full object-cover">
                    <source src="https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/CRM%20&%20ERP.mp4" type="video/mp4" />
                </video>
                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl bg-black bg-opacity-60"></div>
                <div className="relative z-10 px-6 md:px-16 lg:px-10 py-20 md:py-20 max-sm:m-5 bg-opacity-50 backdrop-filter backdrop-blur-sm w-1/2 xl:w-1/2 sm:w-3/4 max-sm:w-full rounded-3xl">
                    <FadeInSectionDownToUp>
                        <h1 className="font-extrabold text-4xl md:text-5xl lg:text-6xl text-white text-center leading-tight">
                            Enterprise Resource Planning
                        </h1>
                        <p className="mt-6 text-lg md:text-xl lg:text-2xl text-gray-200">
                            MH Cognition's ERP system is intended to completely transform how businesses and institutions function. This all-inclusive solution guarantees smooth administration and increased productivity by combining all academic and administrative tasks into a single, cohesive platform.  The purpose of an ERP system is to increase communication, efficiency, and transparency inside organizations. Discover the management of the future with our cutting-edge solution.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>

            <div className="flex justify-center items-center px-80 xl:px-72 sm:px-10 max-sm:px-5 py-20">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5">
                    {solutions.map(({ title, description, imgSrc, gradient, imgWidth }, index) => (
                        <div
                            key={index}
                            className={`relative bg-gradient-to-br hover:bg-gradient-to-tl ${gradient} rounded-3xl text-white p-8 h-[500px]`}
                        >
                            <FadeInSectionDownToUp>
                                <h3 className="text-4xl font-extrabold">{title}</h3>
                                <p className="text-lg pt-5">{description}</p>
                            </FadeInSectionDownToUp>
                            <div className="absolute bottom-10 right-10">
                                <FadeInSectionDownToUp>
                                    <img src={imgSrc} alt={title} className={imgWidth} />
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
