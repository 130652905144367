import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Enterprise() {

    const solutions = [
        {
            title: "LMS",
            description:
                "MH Cognition’s Learning Management System (LMS) is an advanced platform that improves learning for students, teachers, and organizations. It combines AI and interactive content with effective teaching methods to create a flexible and engaging learning experience.",
            image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/LMS.jpg",
            alt: "LMS",
            align: "end",
            viewMore: "/enterprise/learning-management-system",
        },
        {
            title: "ERP",
            description:
                "MH Cognition's ERP system is intended to completely transform how businesses and institutions function. This all-inclusive solution guarantees smooth administration and increased productivity by combining all academic and administrative tasks into a single, cohesive platform.  The purpose of an ERP system is to increase communication, efficiency, and transparency inside organizations. Discover the management of the future with our cutting-edge solution.",
            image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/erp.jpg",
            alt: "ERP",
            align: "start",
            viewMore: "/enterprise/enterprise-resource-planning",
        },
        {
            title: "CRM",
            description:
                "Optimize customer relationships with MH Cognition's innovative CRM platform. Empower your team with tools for lead management, sales tracking, and actionable insights, while ensuring seamless communication and collaboration. Our system supports personalized customer interactions, driving loyalty, efficiency, and business growth.Experience the power of intuitive design and unmatched scalability tailored to your business needs.",
            image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/crm.jpg",
            alt: "CRM",
            align: "end",
            viewMore: "/enterprise/customer-relationship-management",
        },
        // {
        //     title: "Sales and Marketing Solutions.",
        //     description:
        //         "Boost your sales and marketing with our all-in-one solutions. Streamline lead capture, track interactions, and close deals faster with a dynamic CRM. Manage social media, run campaigns, design landing pages, and host webinars - everything you need to engage your audience and drive results.",
        //     image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/sales.jpg",
        //     alt: "Sales Solutions",
        //     align: "end",
        // },
        {
            title: "Human Resources Solutions",
            description:
                "Simplify your recruitment process with MH Cognition. From posting job openings to streamlining candidate selection, our platform helps you attract, evaluate, and hire top talent efficiently. Save time, improve your hiring process, and build stronger teams.",
            image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/HUMAN%20resource%20Solution.jpg",
            alt: "Human Resources Solutions",
            align: "start",
            viewMore: "/enterprise/human-resources-solutions",
        },
        {
            title: "Comprehensive Business Solutions",
            description:
                "Empower developers with our tools designed to simplify and accelerate application development. From low-code solutions to advanced analytics, our platform enables seamless integrations, automation, and robust security, allowing developers to focus on innovation and efficiency.",
            image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/marketing.jpg",
            alt: "Comprehensive Business Solutions",
            align: "end",
            viewMore: "/enterprise/comprehensive-business-solutions",
        },
        {
            title: "Cyber Security Solutions",
            description:
                "Protect your business with our robust cybersecurity solutions. We offer comprehensive services, including threat detection, data encryption, and network security, to safeguard your digital assets. Our proactive approach ensures resilience against cyber threats, giving you the confidence to operate securely in today’s connected world.",
            image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Cyber%20Security%20Solutions..jpg",
            alt: "Cyber Security Solutions",
            align: "start",
            viewMore: "/enterprise/cyber-security-solutions",
        },
        {
            title: "Smart Agro Solutions",
            description:
                "Revolutionize your agricultural practices with our cutting-edge software solutions. We offer comprehensive services, including weather monitoring, auto irrigation, and soil fertility analysis, to streamline and optimize your operations. Our innovative approach ensures increased productivity, sustainability, and resource efficiency, empowering you to thrive in today’s evolving agricultural landscape.",
            image: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/SAS.jpg",
            alt: "Smart Agro Solutions",
            align: "end",
            viewMore: "/enterprise/smart-agro-solutions",
        },
    ];

    return (
        <div>
            <div className='px-64 xl:px-64 sm:px-20 max-sm:px-10 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-3xl'>Simplify, Optimize & Succeed</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-2xl px-20 max-sm:px-0'>
                        Transform your business with our all-in-one platform. Streamline sales, marketing, recruitment, and operations with tailored solutions like CRM, LMS, and ERP.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className="py-20 xl:py-20 sm:py-0 sm:pb-20 max-sm:py-10 px-40 xl:px-40 sm:px-20 max-sm:px-5">
                {solutions.map((solution, index) => (
                    <FadeInSectionDownToUp key={index}>
                        <div
                            className={`flex justify-${solution.align} items-center py-5`}
                        >
                            <div className={`flex ${solution.align === "start" ? "max-sm:flex-wrap-reverse" : "max-sm:block"} justify-between items-center gap-10 max-sm:gap-5 max-sm:w-full`}>
                                {solution.align === "end" && (
                                    <div className='w-1/2 max-sm:w-full'>
                                        <img
                                            className="rounded-3xl"
                                            src={solution.image}
                                            alt={solution.alt}
                                        />
                                    </div>
                                )}
                                <div className={`${solution.align === "end" ? "max-sm:pt-5" : "text-end max-sm:text-start"} w-1/2 max-sm:w-full`}>
                                    <h1 className="text-3xl font-extrabold">{solution.title}</h1>
                                    <p className="text-xl max-sm:text-justify pb-3">
                                        {solution.description}
                                    </p>
                                    <a href={solution.viewMore} className='bg-violet-950 hover:bg-violet-900 text-white p-2 rounded-xl'>View More</a>
                                </div>
                                {solution.align === "start" && (
                                    <div className='w-1/2 max-sm:w-full'>
                                        <img
                                            className="rounded-3xl"
                                            src={solution.image}
                                            alt={solution.alt}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </FadeInSectionDownToUp>
                ))}
            </div>
            <div className='px-96 xl:px-96 sm:px-40 max-sm:px-5'>
                <FadeInSectionDownToUp>
                    <p className='text-4xl max-sm:text-2xl'>
                        Empower Your Business with Our<br />
                        <span className='text-8xl max-sm:text-5xl'><span className='font-bold'>SAAS</span> Solutions</span>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-96 xl:px-96 sm:px-40 max-sm:px-5 py-10'>
                <FadeInSectionDownToUp>
                    <p className='text-2xl max-sm:text-justify'>
                        Our suite of SAAS products is designed to help businesses optimize processes and boost efficiency. From education to enterprise resource planning, we offer customized solutions to meet your needs.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center items-center'>
                <div className='space-y-7'>
                    <FadeInSectionDownToUp>
                        <a href='/enterprise/customer-relationship-management'>
                            {/* <button onClick={() => alert('Product is under Development')}> */}
                            <p className='text-center text-xl bg-black text-white px-10 py-3 rounded-3xl w-56'>
                                <span className=''>CRM</span>
                            </p>
                            {/* </button> */}
                        </a>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <a href='/enterprise/learning-management-system'>
                            {/* <button onClick={() => alert('Product is under Development')}> */}
                            <p className='text-center text-xl bg-black text-white px-10 py-3 rounded-3xl w-56 scale-150'>
                                <span className=''>LMS</span>
                            </p>
                            {/* </button> */}
                        </a>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <a href='/enterprise/enterprise-resource-planning'>
                            {/* <button onClick={() => alert('Product is under Development')}> */}
                            <p className='text-center text-xl bg-black text-white px-10 py-3 rounded-3xl w-56'>
                                <span className=''>ERP</span>
                            </p>
                            {/* </button> */}
                        </a>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='px-40 max-sm:px-5 py-20'>
                <FadeInSectionDownToUp>
                    <p className='text-center text-5xl'>
                        Why Choose <span className='font-semibold'>MH Cognition?</span>
                    </p>
                </FadeInSectionDownToUp>
                <div className='py-10 px-80 xl:px-80 sm:px-40 max-sm:px-0'>
                    <FadeInSectionDownToUp>
                        <p className='text-xl text-justify'>
                            At MH Cognition, we believe in simplifying complexity. Our solutions are designed to integrate seamlessly across Sales, Marketing, and HR, driving greater efficiency and measurable results. With a focus on user-friendly design and powerful functionality, we help your business grow, perform, and succeed.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
