import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function DegreePrograms() {

    const DegreePrograms = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/B.Sc%20in%20Cloud%20computing.jpg",
            alt: "BCA Cloud Computing",
            title: "BCA Cloud Computing",
            link: "/education/degree-programs/bca-cloud-computing",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/B.Sc%20in%20Full%20stack%20developmemt.jpg",
            alt: "B.Sc Computer Science (Full Stack Development)",
            title: "B.Sc Computer Science (Full Stack Development)",
            link: "/education/degree-programs/bsc-in-full-stack-development",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/B.Tech%20AI%20and%20Robotics.jpg",
            alt: "B.Tech in Robotics and Artificial Intelligence",
            title: "B.Tech in Robotics and Artificial Intelligence",
            link: "/education/degree-programs/btech-robotics-and-ai",
        },
        // {
        //     src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/B.Sc%20in%20DevOps.jpg",
        //     alt: "B.Sc Computer Science (Specialization in DevOps)",
        //     title: "B.Sc Computer Science (Specialization in DevOps)",
        //     link: "/education/degree-programs/bsc-in-devops",
        // },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/B.Sc.%20Data%20science%20and%20Cybersecurity.jpg",
            alt: "B.Sc Data Science & Cybersecurity",
            title: "B.Sc Data Science & Cybersecurity",
            link: "/education/degree-programs/bsc-data-science-and-cyber-security",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20cse%20in%20fullstack.jpg",
            alt: "B.Tech - CSE with Full Stack Development",
            title: "B.Tech - CSE with Full Stack Development",
            link: "/education/degree-programs/btech-cse-with-full-stack-development",
        },
        // {
        //     src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20cse%20in%20robotics%20.jpg",
        //     alt: "B.Tech - CSE in Robotics specialization in AI",
        //     title: "B.Tech - CSE in Robotics specialization in AI",
        //     link: "/education/degree-programs/btech-CSE-in-Robotics-Specialization-in-AI",
        // },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20cse%20in%20cloud.jpg",
            alt: "B.Tech - CSE in Cloud Computing Technology",
            title: "B.Tech - CSE in Cloud Computing Technology",
            link: "/education/degree-programs/btech-CSE-in-cloud-computing-technology",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20cse%20in%20data%20analyist.jpg",
            alt: "B.Tech - CSE with Big Data Analytics",
            title: "B.Tech - CSE with Big Data Analytics",
            link: "/education/degree-programs/btech-CSE-with-big-data-analytics",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20cse%20in%20AR%20VR.jpg",
            alt: "B.Tech - CSE in AR & VR",
            title: "B.Tech - CSE in AR & VR",
            link: "/education/degree-programs/btech-CSE-in-AR-&-VR",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20elec%20&%20tele%20eng.jpg",
            alt: "B.Tech in Electronics & Telecommunication Engineering",
            title: "B.Tech in Electronics & Telecommunication Engineering",
            link: "/education/degree-programs/btech-in-electronics-&-telecommunication-engineering",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20elec%20&%20instr%20eng.jpg",
            alt: "B.Tech in Electronics and Instrumentation Engineering",
            title: "B.Tech in Electronics and Instrumentation Engineering",
            link: "/education/degree-programs/btech-in-electronics-and-instrumentation-engineering",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/Btech%20mechatronics%20in%20AI%20and%20ML.jpg",
            alt: "B.Tech in Mechatronics specialization in AI & ML",
            title: "B.Tech in Mechatronics specialization in AI & ML",
            link: "/education/degree-programs/btech-in-mechatronics-specialization-in-AI-&-ML",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/BSc%20cybersecurity.jpg",
            alt: "B.Sc in Cybersecurity",
            title: "B.Sc in Cybersecurity",
            link: "/education/degree-programs/bsc-in-cybersecurity",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/BSc%20data%20science.jpg",
            alt: "B.Sc in Data Science",
            title: "B.Sc in Data Science",
            link: "/education/degree-programs/bsc-in-data-science",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/B.Tech%20Computer%20Science%20and%20Engineering%20(Artificial%20Intelligence%20and%20Data%20Science).jpg",
            alt: "B.Tech Computer Science and Engineering (Artificial Intelligence and Data Science)",
            title: "B.Tech Computer Science and Engineering (Artificial Intelligence and Data Science)",
            link: "/education/degree-programs/btech-computer-science-and-engineering-artificial-intelligence-and-data-science",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Degree%20Programs/B.Tech%20Computer%20Science%20and%20Engineering%20(Cloud%20Computing%20and%C2%A0Cyber%C2%A0Security).jpg",
            alt: "B.Tech Computer Science and Engineering (Cloud Computing and Cyber Security)",
            title: "B.Tech Computer Science and Engineering (Cloud Computing and Cyber Security)",
            link: "/education/degree-programs/btech-computer-science-and-engineering-cloud-computing-and-cyber-security",
        },

    ]

    return (
        <div>
            <div className='p-10'>
                <FadeInSectionDownToUp>
                    <h1 className='font-semibold text-5xl tracking-tighter'>Degree Programs</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 max-sm:grid-cols-1'>
                {DegreePrograms.map((item, index) => (
                    <a href={item.link} key={index} className='bg-opacity-25 shadow-lg shadow-gray-300 m-10 h-max flex flex-col items-center rounded-2xl hover:scale-105 transition-transform duration-1000 ease-in-out' rel="noopener noreferrer">
                        <img src={item.src} alt={item.alt} className='w-full rounded-t-2xl' />
                        <p className='px-5 py-10 text-justify font-semibold text-md'>{item.title}</p>
                    </a>
                ))}
            </div>
            {/* <div className="fixed top-1/3 right-0 z-50 rotate-90">
                <a
                    href="/enquiry-form"
                    rel="noopener noreferrer"
                >
                    <p className="absolute bg-blue-800 hover:bg-blue-900 text-white text-lg text-center py-1 w-[230px] rounded-b-xl animate-bounce">
                        Admissions Open 2025
                    </p>
                </a>
            </div> */}
        </div>
    )
}
