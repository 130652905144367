import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
// import { useNavigate } from 'react-router-dom';

export default function BScinFullStackDevelopment() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    // const navigate = useNavigate();

    const colleges = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/Colleges/VELS.webp",
            title: "Vels University.",
            location: "Chennai.",
            ugc: "UGC Recognised",
            courses: "B.Sc Computer Science (Full Stack Development)",
            button: "Apply Now",
            link: "/vels-university"
        },

    ]

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Degree-Programs/B.Tech%20AI%20and%20Robotics%20-%20Demo1.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>B.Sc <span className='text-[#6b8d36]'>Computer Science (Full Stack Development).</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                                The <b>B.Sc Computer Science (Full Stack Development)</b> is a three-year program designed to prepare students for the dynamic field of web development. The curriculum combines theoretical foundations with practical applications in front-end and back-end technologies, database management, and security protocols. Through hands-on projects and internships, students gain essential industry exposure, equipping them for careers as full stack developers, software engineers, or web application specialists.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
                        <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>3 Years</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Eligibility</p>
                                <ul>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Sr. Secondary (10+2) with minimum 50% marks.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Mathematics / Statistics / Computer / Information Science being one of the subjects or Engineering Diploma.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    {/* <div className='bg-black text-white flex justify-start items-center py-7 px-10 max-sm:px-5 rounded-3xl mt-2 pl-20 max-sm:pl-5'>
                        <FadeInSectionDownToUp>
                            <p className='text-[#6b8d36] font-bold text-xl pb-3'>Accreditation</p>
                            <ul>
                                <div className='flex'>
                                    <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>AICTE (All India Council for Technical Education) recognized</li>
                                </div>
                                <div className='flex'>
                                    <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>UGC (University Grants Commission) recognized</li>
                                </div>
                                <div className='flex'>
                                    <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Industry-validated curriculum</li>
                                </div>
                                <div className='flex'>
                                    <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>International collaborations and partnerships. </li>
                                </div>
                            </ul>
                        </FadeInSectionDownToUp>
                    </div> */}
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-2 gap-2'>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Career Opportunities.</p>
                                <ul>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Full Stack Developer.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Web Application Developer.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Front-End Developer.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Back-End Developer.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Software Engineer.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>UI/UX Designer.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Database Administrator.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Technical Consultant.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Systems Analyst.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>IT Project Manager.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#6b8d36] font-bold text-xl pb-3'>Practical Exposure.</p>
                                <ul>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Laboratory sessions focused on full stack development tools and frameworks like React and Node.js.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Internships with firms specializing in web development and software solutions.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Workshops and seminars led by industry experts.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Capstone projects addressing real-world web application challenges.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Access to facilities for experimentation with various development platforms.</li>
                                    </div>
                                    <div className='flex'>
                                        <li className='text-xl text-[#6b8d36] font-bold ml-2 list-disc'></li><li className=''>Visits to companies employing cutting-edge web technologies.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='py-10'>
                        {/* <FadeInSectionDownToUp>
                            <a href='/private-pilot-license' className='text-[#6b8d36] underline text-3xl'>Course Content</a>
                            </FadeInSectionDownToUp> */}
                        <FadeInSectionDownToUp>
                            <p className='text-3xl text-center font-semibold'>Colleges</p>
                        </FadeInSectionDownToUp>
                        <div className='flex justify-center items-center pt-5'>
                            <div className='grid grid-cols-1 max-sm:grid-cols-1 gap-5 w-1/4 max-sm:w-full'>
                                {colleges.map((card, index) => (
                                    <div key={index} className='rounded-xl shadow-xl hover:scale-105 transition-all ease-linear duration-300'>
                                        <a href={card.link}>
                                        <img className='rounded-t-xl' src={card.src} alt={card.title} />
                                        <div className='px-5 py-5'>
                                            <p className='font-semibold'>{card.title}</p>
                                            <div className='py-2'>
                                                <p>{card.location}📍</p>
                                                <p className='text-gray-400'>({card.ugc})</p>
                                            </div>
                                            <p>{card.courses}</p>
                                            <div className='flex justify-center items-center py-2'>
                                                    <button className='bg-gray-400 hover:bg-gray-300 transition-all ease-linear duration-300 px-5 py-1 rounded-md'>
                                                        <a href={card.link}>
                                                            {card.button}
                                                        </a>
                                                    </button>
                                                </div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="fixed top-1/3 right-0 z-50 rotate-90">
                <a
                    href="/enquiry-form"
                    rel="noopener noreferrer"
                >
                    <p className="absolute bg-blue-800 hover:bg-blue-900 text-white text-lg text-center py-1 w-[230px] rounded-b-xl animate-bounce">
                        Admissions Open 2025
                    </p>
                </a>
            </div> */}
        </div>
    )
}
