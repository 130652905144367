import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function SmartAgroSolutions() {

    const solutions = [
        {
            title: "Weather Monitoring",
            description: "Anticipate and adapt to changing weather patterns with real-time monitoring and accurate forecasting. Our software provides detailed insights into temperature, precipitation, and wind conditions, helping you make informed decisions about planting, irrigation, and harvesting schedules. With this data, you can reduce risks posed by unexpected weather events and optimize your farming activities for maximum yield.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Weather%20Monitoring.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "Auto Irrigation",
            description: "Optimize water usage and save time with our automated irrigation systems. By integrating weather data, soil moisture levels, and crop requirements, our solution ensures precise hydration for your fields. This reduces water wastage and minimizes manual labor, promoting sustainable farming practices while maintaining healthy crops.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Auto%20Irrigation.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Soil Moisture Level Checking",
            description: "Gain real-time insights into your soil's moisture levels to avoid over- or under-watering. Our solution continuously monitors and analyzes soil conditions, enabling you to maintain the ideal environment for crop growth. This ensures efficient water use and prevents stress on your plants due to inconsistent hydration.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Soil%20Moisture%20Level%20Checking.png",
            gradient: "from-[#7fd57e] to-[#1ca14e]",
            imgWidth: "w-16",
        },
        {
            title: "Soil Fertility Level Checking",
            description: "Understand the nutrient profile and fertility of your soil with our advanced analysis tools. By identifying deficiencies or imbalances, our software helps you implement precise fertilization plans tailored to your soil's needs. This leads to healthier crops, improved yields, and long-term soil sustainability.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Soil%20Fertility%20Level%20Checking.png",
            gradient: "from-[#db7048] to-[#b94a25]",
            imgWidth: "w-16",
        },
        {
            title: "Plant Growth Monitoring",
            description: "Monitor the growth and health of your crops with precision using our plant growth tracking tools. From seedling to harvest, our system provides actionable insights into plant development, helping you identify and address issues early. This ensures optimal crop quality and timely harvesting for maximum profitability.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Plant%20Growth%20Monitoring.png",
            gradient: "from-[#26a2af] to-[#173362]",
            imgWidth: "w-16",
        },
        {
            title: "Land Mapping",
            description: "Visualize your farmland like never before with our advanced land mapping technology. Our software helps you analyze terrain, plot boundaries, and optimize land use for various activities. By streamlining resource allocation and planning, you can maximize efficiency and productivity across your fields.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Land%20Mapping.png",
            gradient: "from-[#c70f60] to-[#690127]",
            imgWidth: "w-16",
        },
        {
            title: "Livestock Management",
            description: "Ensure the health and productivity of your livestock with our comprehensive management system. From tracking animal health and nutrition to streamlining breeding and production cycles, our solution simplifies livestock operations. This enables better decision-making and enhances animal welfare while improving operational efficiency.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Livestock%20Management.png",
            gradient: "from-[#914fa5] to-[#41176d]",
            imgWidth: "w-16",
        },
        {
            title: "Pest Control",
            description: "Protect your crops from pests with our intelligent pest control solution. By detecting and monitoring pest activity, our system provides timely alerts and actionable strategies to minimize damage. This reduces dependency on chemical pesticides, promoting eco-friendly and cost-effective pest management practices.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Pest%20Control.png",
            gradient: "from-[#f6592d] to-[#fa3d8f]",
            imgWidth: "w-16",
        },
        {
            title: "Crop Rotation Planning",
            description: "Preserve soil health and improve crop yields with intelligent crop rotation strategies. Our software analyzes soil conditions, weather data, and market trends to recommend optimal rotation plans. This minimizes nutrient depletion, prevents soil erosion, and supports sustainable farming.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Crop%20Rotation%20Planning.png",
            gradient: "from-[#fb2c08be] to-[#7499f8]",
            imgWidth: "w-16",
        },
        {
            title: "Scheduling",
            description: "Simplify your farming operations with our intuitive scheduling tools. Plan and organize tasks such as planting, watering, fertilizing, and harvesting with precision. By streamlining daily activities, our solution ensures timely execution and boosts overall productivity.",
            imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Cognition/Enterprise/Agro/Scheduling.png",
            gradient: "from-[#74bafb] to-[#318e94]",
            imgWidth: "w-16",
        },
    ];
    

    return (
        <div>
            {/* <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/Agro_2.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-64 xl:px-64 sm:px-5 max-sm:px-5 py-20 max-sm:py-10 text-center'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold py-2 text-5xl max-sm:text-2xl'>Smart Agro Solutions</p>
                    <p className='font-semibold py-2 text-gray-400 text-3xl max-sm:text-xl px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                        Welcome to MH Cognition, where innovation meets agriculture. We specialize in providing advanced software solutions tailored to revolutionize the agricultural landscape. With cutting-edge technology and data-driven insights, our services empower farmers, agribusinesses, and agricultural professionals to optimize operations, enhance productivity, and ensure sustainability.
                    </p>
                </FadeInSectionDownToUp>
            </div> */}
            <div className="relative h-screen flex items-center justify-center">
                <video autoPlay loop playsInline className="absolute inset-0 w-full h-full object-cover">
                    <source src="https://mh-cognition.blr1.cdn.digitaloceanspaces.com/Enterprise/Agro_2.mp4" type="video/mp4" />
                </video>
                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl bg-black bg-opacity-60"></div>
                <div className="relative z-10 px-6 md:px-16 lg:px-10 py-20 md:py-20 max-sm:m-5 bg-opacity-50 backdrop-filter backdrop-blur-sm w-1/2 xl:w-1/2 sm:w-3/4 max-sm:w-full rounded-3xl">
                    <FadeInSectionDownToUp>
                        <h1 className="font-extrabold text-4xl md:text-5xl lg:text-6xl text-white text-center leading-tight">
                            Smart Agro Solutions
                        </h1>
                        <p className="mt-6 text-lg md:text-xl lg:text-2xl text-gray-200">
                            Welcome to MH Cognition, where innovation meets agriculture. We specialize in providing advanced software solutions tailored to revolutionize the agricultural landscape. With cutting-edge technology and data-driven insights, our services empower farmers, agribusinesses, and agricultural professionals to optimize operations, enhance productivity, and ensure sustainability.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className="flex justify-center items-center px-80 xl:px-72 sm:px-10 max-sm:px-5 py-20">
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm:gap-5">
                    {solutions.map(({ title, description, imgSrc, gradient, imgWidth }, index) => (
                        <div
                            key={index}
                            className={`relative bg-gradient-to-br hover:bg-gradient-to-tl ${gradient} rounded-3xl text-white p-8 h-[500px]`}
                        >
                            <FadeInSectionDownToUp>
                                <h3 className="text-4xl font-extrabold">{title}</h3>
                                <p className="text-lg pt-5">{description}</p>
                            </FadeInSectionDownToUp>
                            <div className="absolute bottom-10 right-10">
                                <FadeInSectionDownToUp>
                                    <img src={imgSrc} alt={title} className={imgWidth} />
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
